.footerContainer{
    /* d-flex flex-row justify-content-center fixed-bottom bg-dark footer-height */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    /* max-width: 372px; */
    min-width: 280px;
    background: #252525;
    height: 3em;
    z-index: 9;
}
.footerContainerCenter {
    display: flex;
     max-width: 372px;
     min-width: 340px;
}
.footerBtn{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 100px;
    /* width: 20%;
    max-width: 20%;
    min-width: 10%; */
    
}
.footerLink {
    color:white;
    text-decoration: none;
    display: flex;
    justify-content: center;
}
.linkName{
    font-size: 9px;
    position: absolute;
    bottom: 9px;
}
img,svg{
    width: 18px;
}

svg path {
    /* stroke: white; */
}

.active{
    color: #FFE016 !important;
}

.homeic {
    background: #FFE016;
    padding: 5px;
    border-radius: 100px;
    width: 38px;
}
/* .activeLink svg path{
    fill: #db7119 !important;
} */

