.backToVideosBtnContainer{
    display: flex;
    position: absolute;
    top: 15px;
    left: 15px;
    margin-bottom: 0 !important;
    cursor: pointer;
}
.skipQuestionBtnContainer{
    position: absolute;
    /* top: 3.5em; */
    right: 5px;
    color: white;
    z-index: 999;
}
.backToVidesoButton{
    background-color: #3d3c3a;
    height: auto;
    min-height: 35px;
    width: 130px;
    min-width: 120px;
    padding: 8px 0;
    border-radius: 25px;
    color: white;
    font-family: "open_sans_regular";
    font-size: 15px;
    box-shadow: 0px 7px 0 0 #b66a2a;
    border: none;
    letter-spacing: 1.2px;
}
.skipQuestionButton{
    background-color: transparent;
    height: auto;
    min-height: 35px;
    width: 80px;
    min-width: 80px;
    padding: 8px 0;
    border-radius: 25px;
    color: white;
    font-family: "open_sans_regular";
    font-size: 15px;
    /* box-shadow: 0px 7px 0 0 #b66a2a; */
    border: none;
    letter-spacing: 1.2px;
}
.QuestionTimeContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding-top: 3em;
    padding-bottom: 2em;
}

.questionStatement {
    flex: 0 0 auto; /* Prevents question from scrolling */
    font-size: 14px;
    width: 70%;
    margin: 0 auto;
    margin-bottom: 15px;
    padding: 20px 5px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    min-height: 150px;
    min-width: 320px;
    border-radius: 35px;
}

.questionStatement p {
    margin-bottom: 0;
}
.questionTitle{
    font-family: open_sans_bold !important;
    font-size: 20px;
    text-align: center;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: white;
}
.mb_0 {
    margin-bottom: 0;
}
.mb_1 {
    margin-bottom: 1.7em;
}
.displayNone{
    display: none;
}
.answersContainer {
    flex: 1;
    overflow-y: auto;
    padding: 0 20px;
    margin-bottom: 1rem;
}
.answerBtn {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(20px);
    height: auto;
    min-height: 30px;
    width: 70%;
    min-width: 280px;
    margin: 0 auto;
    margin-bottom: 0;
    padding: 10px 15px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: open_sans_regular;
    font-size: 18px;
    /* box-shadow: 0px 7px 0 0 #b66a2a; */
    border: none;
    letter-spacing: 1.2px;
}
.answerBtn:active {
    transform: translateY(3px);
    box-shadow: none;
}

.answerIsCorrect {
    background-color: green;
}
.answerIsInCorrect {
    background-color: red;
}
.corectAnswerPrompt{
    /* position: fixed;
    left: 0;
    right: 0;
    bottom: 100px; */
    display: flex;
    justify-content: center;
}
.corectAnswerPrompt p {
    font-size: 1rem;
    color: white;
    text-align: center;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%,-50%); */
    /* animation: MoveUpDown 3s linear infinite; */
}
.visibilityHidden{
    visibility: hidden !important;
}

@keyframes MoveUpDown {
    0%, 100% {
      bottom: 0;
    }
    50% {
      bottom: 50%;
    }
    
  }
.hidden{
    display: none;
}

  /* timer */
  .counter{
    margin-top: 15px;
    /* height: 70px; */
    align-items: center;
  }
  .timerWrapper{
    width: 35px;
    height: 85px;
    max-width: 35px;
    display: flex;
    justify-content: center;
    margin-right: 5px;
    margin-top: 5px;
}
.starToken{
    height: 65px;
    width: 75px;
}
.moveableTimer{
    position: absolute;
    top: 6px;
    display: flex;
    justify-content: center;
    animation: bounce 4s ease 0s infinite normal forwards;
}
.secondsCounter{
    font-family: "rifficBold";
    position: absolute;
    font-size: 30px;
    background: #cc0f0f;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    border: 1px solid #f0c619;
}
.dingToken{
    width: 65px;
    height: 65px;
}
.timerSmoke{
    position: absolute;
    width: 280px;
    height: 180px;
    top: -47px;
}
.mt_equal{
    margin-top: 10px;
}
/* .borderBottom {
    border-bottom: 2px solid red;
} */
.colorWhite {
    color: white;
}
.ml {
    margin-left: 5px;
}



@keyframes bounce {
	0%,
	100% {
		transform: translateY(-5px);
	}

	10%,
	30%,
	50%,
	70% {
		transform: translateY(-15px);
	}

	20%,
	40%,
	60% {
		transform: translateY(-5px);
	}

	80% {
		transform: translateY(-5px);
	}

	90% {
		transform: translateY(-15px);
	}
}

@media screen and (max-width: 375px) {
    .questionStatement, .answerBtn {
        min-width: 250px;
    }
    .questionTitle {
        font-size: 15.5px;
    }
    .skipQuestionBtnContainer {
        right: 0;
        padding: 0
    }
    .QuestionTimeContainer {
        padding-bottom: 5em !important;
    }
    .answerBtn {
        font-size: 15px;
    }
}

@media screen and (max-width: 320px) {
    .questionStatement, .answerBtn {
        min-width: 210px;
    }
    .QuestionTimeContainer {
        padding-bottom: 10em !important;
    }

    .questionTitle {
        font-size: 14px;
    }

    .answerBtn {
        font-size: 13px;
    }
}