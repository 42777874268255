.main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    transition: padding-top 0.25s ease-in;
    max-width: 550px;
    width: 100%;
    margin: auto;
    padding-bottom: 4.5rem;
}
.insufficentCoins{
    width: 250px;
    display: flex;
    margin: 0 auto;
    box-shadow: 0 0 5px 0 #3d3c3a;
    height: 30px;
    align-items: center;
    background: #3d3c3a;
    position: fixed;
    z-index: 1;
    top: 50px;
    transition: opacity .3s ease-out;
    
}
.insufficentCoins p {
    font-family: "outfitBold";
    background: #3d3c3a;
    width: 100%;
    text-align: center;
}
.hidden{
    opacity: 0;
}
.show{
    opacity: 1;
}

.section{
    display: flex;
    flex-direction: row;
    padding: 0 15px;
    /* width: 400px; */
    width: 100%;
    margin-bottom: 15px;
    height: 103px;
    transition: height 0.25s ease-in;
}

.leftSide{
    flex-basis: 100px;
    height: 100px;
    padding: 0 0 0 15px;
}
.img{
    width: 100%;
    height: 110px;
}


.text{
    line-height: 1.4;
    letter-spacing: 1px;
    font-size: 16px;
}
.rightSideBtn{
    text-align: right;
    margin-top: 30px;
}

.btn{
    color: white;
    background-color: #252525;
    padding: 6px 5px;
    width: 60%;
    min-width: 46%;
    max-width: 100%;
    border-radius: 25px;
    border: none;
    transition: .1s ease;
    box-shadow: 0px 2px 4px 1px #252525;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}
.btn:active{
    box-shadow: none;
    transform: translateY(2px);
}

.backToVidesoButton{
    max-width: 300px;
    min-width: 270px;
    width: 300px;
    font-size: 18px;
    font-family: "rifficBold";
    color: white;
    background: #3d3c3b;
    padding: 5px;
    width: 100%;
    border-radius: 25px;
    box-shadow: 0px 6px 0 0 #b57c28;
    border: none;
    transition: .5s ease;
}
.redeemCard{
    position: relative;
    height: 220px;
    width: 90vw;
    padding: 25px;
    margin: 0 auto;
    width: 90%;
    max-width: 550px;
}
.cardImg{
    position: absolute;
    top: -22px;
    left: -26px;
    right: 0;
    bottom: 0;
    width: 100vw;
    max-width: 550px;
    height: 267px;
    z-index: -1;
}

@media screen and (min-height: 750px) {
        .main{
            /* padding-top: 100px */
        }
        .section{
            height: 110px;
        }
}

@media screen and (min-height: 803px) {
    .main{
        /* padding-top: 110px */
    }
    .section{
        height: 115px;
    }
}
@media screen and (min-height: 844px) {
    .main{
        /* padding-top: 120px */
    }
}